import React from 'react';
import styles from './BePromotedText.module.scss';

const BePromotedText = () => (
    <div className={styles.wrapper}>
        <h2
            data-aos="fade-right"
            data-aos-delay="0"
            data-aos-duration="500"
        >Wypromuj się w <span>Internecie</span></h2>
        <p
            data-aos="fade-left"
            data-aos-delay="500"
            data-aos-duration="500"
        >Strona Internetowa to dopiero początek. Na jej sukces składa się atrakcyjny wygląd, intuicyjna obsługa, szybkość działania, odpowiednia treść oraz rozwiązania technologiczne.</p>
        <p
            data-aos="fade-right"
            data-aos-delay="1000"
            data-aos-duration="500"
        >Co więcej, nawet najlepszy projekt nie przyniesie korzyści bez odpowiedniej promocji w Internecie. Dlatego proponujemy Ci kompleksową obsługę w zakresie nowych mediów.</p>
    </div>
);

export default BePromotedText;