import React from 'react';
import styles from './ModernTechnologies.module.scss'
import ModernTechnologiesBrands from './ModernTechnologiesBrands'
import ModernTechnologiesText from './ModernTechnologiesText'

const ModernTechnologies = () => (
    <div className={styles.wrapper}>
        <ModernTechnologiesBrands />
        <ModernTechnologiesText />
    </div>
);

export default ModernTechnologies;