import React from 'react'
import PropTypes from 'prop-types'
import styles from './ContactInput.module.scss'

const Input = ({tag: Tag, name, placeholder, maxLength, type, onChangeFn}) => (
  <Tag
    placeholder={placeholder}
    className={styles.contact__input}
    type={type}
    id={name}
    name={name}
    required
    maxLength={maxLength}
    onChange={onChangeFn}
  />
);

Input.propType = {
  tag: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
}

Input.defaultProps = {
  tag: 'input'
}

export default Input;