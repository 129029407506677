import React from 'react';
import styles from './BePromoted.module.scss';
import bePromotedImg from '../../assets/wypromuj-strone.webp';
import BePromotedText from './BePromotedText';

const BePromoted = () => (
    <div className={styles.wrapper} id="bePromotedPage">
        <BePromotedText />
        <img src={bePromotedImg} alt="Wypromuj się w internecie"
            className={styles.image}
            data-aos="zoom-in"
            data-aos-duration="1500"
        />
    </div>
);

export default BePromoted;