import React, { Component } from 'react';
import { Link } from "react-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './Menu.module.scss';
import './Menu.css';

class Menu extends Component {
    render() {
        return (
            <div data-aos="fade-left" className={styles.wrapper}>
                <button
                    className={styles.menuBtn}
                    onClick={this.props.hideMenuFn}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className={styles.content}>
                    <ul className={styles.navItems}>
                        <li className={styles.navItem}>
                            <Link
                                activeClass="navItem active"
                                to="offerPage"
                                spy={true}
                                smooth={true}
                                duration={200}
                                offset={-76}
                            >
                                Oferta
                            </Link>
                        </li>
                        <li className={styles.navItem}>
                            <Link
                                activeClass="navItem active"
                                to="portfolioPage"
                                spy={true}
                                smooth={true}
                                duration={200}
                                offset={-76}
                            >
                                Portfolio
                            </Link>
                        </li>
                        <li className={styles.navItem}>
                            <Link
                                activeClass="navItem active"
                                to="contactPage"
                                spy={true}
                                smooth={true}
                                duration={200}
                                offset={-76}
                            >
                                Kontakt
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
};

export default Menu;