import React from 'react'
import styles from './Portfolio.module.scss'
import PortfolioBox from './PortfolioBox'
import osiedleczarneckiegoImg from '../../assets/osiedle-czarneckiego.webp'
import agatagossImg from '../../assets/agata-goss.webp'
import malgorzatalibudaImg from '../../assets/malgorzata-libuda.webp'
import fryzjerrybnikImg from '../../assets/fryzjer-rybnik.webp'

const Portfolio = () => (
    <div className={styles.wrapper} id="portfolioPage">
        <h2 className={styles.title}>Portfolio</h2>
        <div className={styles.portfolioList}>
            <PortfolioBox
                link="www.osiedleczarneckiego.pl"
                title="Osiedle Czarneckiego"
                img={osiedleczarneckiegoImg}
            />
            <PortfolioBox
                link="www.agatagoss.pl"
                title="Agata Goss"
                img={agatagossImg}
            />
            <PortfolioBox
                link="www.malgorzatalibuda.com"
                title="Małgorzata Libuda"
                img={malgorzatalibudaImg}
            />
            <PortfolioBox
                link="www.fryzjermobilnyrybnik.pl"
                title="Fryzjer Mobilny Rybnik"
                img={fryzjerrybnikImg}
            />
        </div>
    </div>
);

export default Portfolio;