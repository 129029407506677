import React from 'react'
import styles from './ModernTechnologiesBrands.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faJs, faReact, faSass, faNodeJs, faCss3Alt, faHtml5 } from '@fortawesome/free-brands-svg-icons';

const Brands = () => (
    <div className={styles.wrapper}>
        <div className={styles.iconBox}
            data-aos="fade-right"
            data-aos-delay="0"
            data-aos-duration="200"
            style={{color: '#e34f26'}}
        >
            <FontAwesomeIcon icon={faHtml5} />
        </div>
        <div className={styles.iconBox}
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="200"
            style={{color: '#002561'}}
        >
            <FontAwesomeIcon icon={faCss3Alt} />
        </div>
        <div className={styles.iconBox}
            data-aos="fade-right"
            data-aos-delay="400"
            data-aos-duration="200"
            style={{color: '#f7df1e'}}
        >
            <FontAwesomeIcon icon={faJs} />
        </div>
        <div className={styles.iconBox}
            data-aos="fade-left"
            data-aos-delay="600"
            data-aos-duration="200"
            style={{color: '#00d8ff'}}
        >
            <FontAwesomeIcon icon={faReact} />
        </div>
        <div className={styles.iconBox}
            data-aos="fade-left"
            data-aos-delay="800"
            data-aos-duration="200"
            style={{color: '#c69'}}
        >
            <FontAwesomeIcon icon={faSass} />
        </div>
        <div className={styles.iconBox}
            data-aos="fade-left"
            data-aos-delay="1000"
            data-aos-duration="200"
            style={{color: '#6cc24a'}}
        >
            <FontAwesomeIcon icon={faNodeJs} />
        </div>
    </div>
);

export default Brands;