import React from 'react';
import styles from './PortfolioBox.module.scss'

const PortfolioBox = (props) => (
    <div className={styles.wrapper}>
        <figure className={styles.thumb}>
            <img src={props.img} alt={props.title} />
        </figure>
        <p className={styles.link}>{props.title}</p>
    </div>
);

export default PortfolioBox;