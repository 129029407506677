import React from 'react'
import styles from './ModernTechnologiesText.module.scss'

const ModernTechnologiesText = () => (
    <div className={styles.wrapper}
        data-aos="fade-left"
        data-aos-delay="0"
        data-aos-duration="500"
    >
        <h2>Więcej niż <strong>strona internetowa</strong></h2>
        <p>Niektórzy odwiedzą stronę korzystając z telefonu czy tabletu, kolejni mogą używać laptopa lub standardowego komputera. Jednych przekona merytoryczny tekst, innych ciekawe animacje. Dlatego wszystkie tworzone przez nas serwisy www są dostosowane do urządzeń mobilnych.</p>
        <p>Korzystamy tylko z najnowocześniejszych technologii dlatego nasze strony są szybkie, bezpieczne i dostępne.</p>
        <p>Strony, które kodujemy nie opierają się na gotowych rozwiązaniach takich jak page buildery czy darmowe bądź płatne motywy. Tworzymy projekty od podstaw - dopasowane i zoptymalizowane.</p>
    </div>
);

export default ModernTechnologiesText;