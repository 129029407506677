import React from 'react';
import styles from './Intro.module.scss';
import { Link } from "react-scroll";

const Intro = () => (
    <div className={styles.wrapper} id="section-0">
        <div className={styles.content}>
            <h1
                data-aos="fade-down"
                data-aos-duration="500"
            >Strony internetowe,<br />
            tworzenie i projektowanie<br />stron www</h1>
            <div
                data-aos="flip-left"
                data-aos-delay="500"
                data-aos-duration="500"
            >
                <p>Rozwiń swoją firmę w Internecie.</p>
                <p>Profesjonalne usługi zwiększające ilość klientów, które znajdziesz w jednym miejscu.</p>
                <p>Od analizy, przez projekt po marketing w wyszukiwarkach.</p>
            </div>
            <div
                data-aos="fade-up"
                data-aos-delay="1000"
                data-aos-duration="500"
                className={styles.marginTop}
            >
                <p><Link
                    className={styles.btn}
                    to="contactPage"
                    smooth={true}
                    duration={800}
                    offset={-76}
                >Skontaktuj się z nami</Link></p>
            </div>
        </div>
        <div className={styles.introTxt}>
            <p><Link
                    to="bePromotedPage"
                    smooth={true}
                    duration={800}
                    offset={-150}
                >Przewiń w dół</Link>
            </p>
        </div>
    </div>
);

export default Intro;