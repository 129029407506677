import React, { Component } from 'react'
import styles from './FAQ.module.scss'
// import './FAQ.css'

class FAQ extends Component {
  // state = {
  //   on: false,
  // }

  // toggle = () => {
  //   this.setState({
  //     on: !this.state.on,
  //   })
  // }

  render() {
    return (
      <>
        <div className={styles.headerBox}>
          <h2>
            {this.props.title}
          </h2>
          {/* <span>Zobacz więcej</span> */}
        </div>
        <div className={styles.faqContent}>
          {this.props.children}
        </div>
      </>
    )
  }
}

export default FAQ;