import React, { Component } from 'react'
import Navbar from './components/Navbar/Navbar'
import Intro from './components/Intro/Intro'
import Menu from './components/Menu/Menu'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
import BePromoted from './components/BePromoted/BePromoted'
import OfferIcons from './components/OfferIcons/OfferIcons'
import ModernTechnologies from './components/ModernTechnologies/ModernTechnologies'
import Portfolio from './components/Portfolio/Portfolio'
import FAQWrapper from './components/FAQ/FAQWrapper';

class App extends Component {
  state = {
      isMenuVisible: false,
      isWhite: false,
  }

  openMenu = () => {
    this.setState({
      isMenuVisible: true,
    })
  }

  hideMenu = () => {
    this.setState({
      isMenuVisible: false,
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      const isIntro = window.scrollY < 20;
      if (isIntro !== true) {
        this.setState({ isWhite: true })
      } else {
        this.setState({ isWhite: false })
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll');
  }

  render() {
    const { isMenuVisible } = this.state;

    return (
      <>
        <Navbar
          openMenuFn={ this.openMenu }
          navWhite={this.state.isWhite}
        />
        { isMenuVisible &&
          <Menu
            hideMenuFn={ this.hideMenu }
          />
        }
        <Intro />
        <BePromoted />
        <OfferIcons />
        <ModernTechnologies />
        <Portfolio />
        <Contact />
        <FAQWrapper />
        <Footer />
      </>
    );
  }
}

export default App;
