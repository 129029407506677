import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import styles from './Navbar.module.scss';
import { animateScroll as scroll } from "react-scroll";

class Navbar extends Component {
    scrollToTop = () => {
        scroll.scrollToTop({
            duration: 200,
        }); 
    };

    render() {
        return (
            <nav
                className={this.props.navWhite ? styles.wrapperBlack : styles.wrapper }
            >
                <p
                    className={styles.logo}
                    onClick={this.scrollToTop}
                >
                    doubles
                </p>
                <button
                    className={styles.menuBtn}
                    onClick={this.props.openMenuFn}
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </nav>
        )
    }
};

export default Navbar;