import React from 'react';
import styles from './OfferIcons.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faChartLine, faShoppingBasket, faGem } from '@fortawesome/free-solid-svg-icons';

const OfferIcons = () => (
    <div className={styles.wrapper} id="offerPage">
        <ul className={styles.offerList}>
            <li>
                <FontAwesomeIcon icon={faDesktop} />
                <h4>Strony internetowe</h4>
            </li>
            <li>
                <FontAwesomeIcon icon={faShoppingBasket} />
                <h4>Sklepy internetowe</h4>
            </li>
            <li>
                <FontAwesomeIcon icon={faGem} />
                <h4>Identyfikacja wizualna</h4>
            </li>
            <li>
                <FontAwesomeIcon icon={faChartLine} />
                <h4>Pozycjonowanie</h4>
            </li>
        </ul>
    </div>
);

export default OfferIcons;