import React from 'react';
import styles from './ContactText.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ContactText = () => (
  <div className={styles.wrapper}>
    <div className={styles.contact__row}>
      <h3>Dane kontaktowe</h3>
      <p>
        <span className={styles.contact__ico}>
          <FontAwesomeIcon icon={faMobileAlt} />
        </span>
        <a href="tel:+48530919728">+48 530 919 728</a>
      </p>
      <p>
        <span className={styles.contact__ico}>
          <FontAwesomeIcon icon={faEnvelope} />
        </span>
        <a href="mailto:biuro@doubles.pl">biuro@doubles.pl</a>
      </p>
    </div>
    <div className={styles.contact__row}>
      <h3>Godziny pracy</h3>
      <p>Poniedziałek - Sobota:</p>
      <p>
        <span className={styles.contact__ico}>
          <FontAwesomeIcon icon={faClock} />
        </span>
        8:00 - 20:00
      </p>
    </div>
  </div>
);

export default ContactText;