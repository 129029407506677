import React from 'react';
import ContactForm from './ContactForm';
import ContactText from './ContactText';
import styles from './Contact.module.scss';

const Contact = () => (
  <div className={styles.wrapper} id="contactPage">
    <div className={styles.colLeft}
      data-aos="fade-right"
      data-aos-delay="0"
      data-aos-duration="500"
    >
      <ContactText />
    </div>
    <div className={styles.colReft}
      data-aos="fade-left"
      data-aos-delay="500"
      data-aos-duration="500"
    >
      <ContactForm />
    </div>
  </div>
);

export default Contact;