import React, { Component } from 'react';
import styles from './ContactForm.module.scss';
import axios from 'axios';
import Input from './ContactInput';

const API_PATH = '/api/mailer.php';

class ContactForm extends Component {
  state = {
    fname: '',
    tel: '',
    email: '',
    message: '',
    mailSent: false,
    error: null,
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: this.state
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          fname: '',
          tel: '',
          email: '',
          message: '',
        })
      })
      .catch(error => this.setState({ error: error.message }));
  };

  render () {
    return (
      <div className={styles.wrapper}>
        {this.state.mailSent ?
          <p>Wiadomość została wysłana.</p>
          :
          <form onSubmit={this.handleFormSubmit}>
            <Input
              name="fname"
              type="text"
              placeholder="Twoje imię"
              maxLength={40}
              value={this.state.fname}
              onChangeFn={e => this.setState({ fname: e.target.value })}
            />
            <Input
              type="text"
              name="lname"
              placeholder="Twój numer telefonu"
              maxLength={14}
              value={this.state.tel}
              onChangeFn={e => this.setState({ tel: e.target.value })}
            />
            <Input
              type="email"
              name="email"
              placeholder="Twój email"
              maxLength={60}
              value={this.state.email}
              onChangeFn={e => this.setState({ email: e.target.value })}
            />
            <Input
              tag="textarea"
              name="subject"
              placeholder="Twoja wiadomość"
              maxLength={300}
              value={this.state.message}
              onChangeFn={e => this.setState({ message: e.target.value })}
            />
            <button
              type="submit"
              className={styles.contact__submit}>WYŚLIJ</button>
          </form>
        }
        
      </div>
    )
  }
};

export default ContactForm;